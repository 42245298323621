<template>
  <BOverlay :show="isProcessing">
    <h4>{{ $t('logs') }}</h4>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>{{ $t('reservation-moduel.type') }}</th>
          <th>{{ $t('class-module.start-time') }}</th>
          <th>{{ $t('class-module.end-time') }}</th>
          <th>{{ $t('actions.status') }}</th>
          <th>{{ $t('actions.action') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log, index of logs"
            :key="index"
        >
          <td>
            <b-badge v-if="log.type === 'partner_schedule'"
                     variant="secondary"
            >
              {{ $t('navigation-menu.schedule') }}
            </b-badge>
            <b-badge
              v-else-if="log.type === 'partner_follow_up'"
              variant="warning"
            >
              {{ $t('calendar-module.follow-up') }}
            </b-badge>
            <b-badge
              v-else
              variant="primary"
            >
              {{ $t('student-import') }}
            </b-badge>
          </td>
          <td>
            {{ FORMAT_DATE(log.created_at, 'MMMM Do YYYY, h:mm:ss a') }}
          </td>
          <td>
            {{ log.updated_at ? FORMAT_DATE(log.updated_at, 'MMMM Do YYYY, h:mm:ss a') : '' }}
          </td>
          <td>
            <div v-if="log.status === 'in_progress'"
                 variant="primary"
            >
              <b-spinner
                variant="primary"
                class="mr-1"
                type="grow"
              />     <b-badge
                variant="primary"
              >
                {{ $t('courses-module.in_progress') }}
              </b-badge>
            </div>
            <b-badge v-else-if="log.status === 'completed'"
                     variant="success"
                     style="margin-top: -10px;"
            >
              {{ $t('courses-module.completed') }}
            </b-badge>
            <b-badge v-else-if="log.status === 'failed'"
                     variant="danger"
            >
              {{ $t('Error') }}
            </b-badge>
          </td>
          <td>
            <b-button
              v-if="log.logs"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="logToShow = log"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
            <b-button
              v-if="log.file"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="downloadFile(log.file, log.type + '_' + FORMAT_DATE(log.created_at, 'YYYY-MM-DD'))"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
            <b-button
              v-if="log.logs?.created_problems?.length"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="getProblemInfo(log)"
            >
              <b-spinner v-if="log.isProcessing"
                         small
              />
              <feather-icon v-else
                            icon="PackageIcon"
              />
            </b-button>
            <b-button
              v-if="log.status === 'failed'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon"
              @click="confirmRetry(log)"
            >
              <b-spinner v-if="log.isRetrying"
                         small
              />
              <feather-icon v-else
                            icon="RefreshCwIcon"
              />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>
    <LogDetail v-if="!!logToShow"
               :log-info="logToShow"
               @close="logToShow = null"
    />
    <Pagination :pagination="pagination"
                @onPaginationChange="getPartnerExcelLogs"
    />
  </BOverlay>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BBadge, BSpinner, BOverlay,
} from 'bootstrap-vue'
import exportFromJSON from 'export-from-json'
import Ripple from 'vue-ripple-directive'
import Pagination from '@/views/common/components/Pagination.vue'
import LogDetail from './Detail.vue'

export default {
  components: {
    BButton,
    LogDetail,
    BBadge,
    BSpinner,
    Pagination,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logs: [],
      logToShow: null,
      isProcessing: false,
      pagination: {
        total: 0,
        per_page: 0,
        current_page: 1,
        last_page: 1,
      },
    }
  },
  mounted() {
    this.getPartnerExcelLogs()
  },
  methods: {
    downloadFile(fileUrl, name) {
      // Create an anchor element
      const a = document.createElement('a')
      a.href = fileUrl
      // Set the download attribute with the desired file name
      a.download = `${name}.csv`

      // Append the anchor element to the document body
      document.body.appendChild(a)

      // Programmatically trigger a click event on the anchor element
      a.click()

      // Cleanup: remove the anchor element from the document body
      document.body.removeChild(a)
    },
    getProblemInfo(log) {
      // eslint-disable-next-line no-param-reassign
      log.isProcessing = true
      const exportType = 'csv'
      const fileName = 'problemData'
      useJwt.getProblemDataFromLogs(log.id).then(response => {
        exportFromJSON({
          data: response.data.data,
          fileName,
          exportType,
        })
        this.showSuccessMessage(response)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        // eslint-disable-next-line no-param-reassign
        log.isProcessing = false
      })
    },
    confirmRetry(log) {
      this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to retry this import?'), {
        title: this.$t('Confirm Retry'),
        okVariant: 'danger',
        okTitle: this.$t('Yes'),
        cancelTitle: this.$t('No'),
      }).then(value => {
        if (value) {
          this.retryImport(log)
        }
      })
    },
    retryImport(log) {
      // eslint-disable-next-line no-param-reassign
      log.isRetrying = true
      useJwt.retryLaImport(log.id).then(response => {
        this.showSuccessMessage(response)
        location.reload()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        // eslint-disable-next-line no-param-reassign
        log.isRetrying = false
      })
      // useJwt.retryImport(log.id).then(response => {
      //   this.showSuccessMessage(response)
      //   this.getPartnerExcelLogs(this.pagination.current_page)
      // }).catch(error => {
      //   this.showErrorMessage(error)
      // }).finally(() => {
      //   // eslint-disable-next-line no-param-reassign
      //   log.isRetrying = false
      // })
    },
    getPartnerExcelLogs(page = 1) {
      this.isProcessing = true
      useJwt.getPartnerExcelLogs({
        params: {
          page,
        },
      }).then(response => {
        const responseData = response.data.data
        this.logs = responseData.data.map(item => ({
          ...item,
          isProcessing: false,
          isRetrying: false,
        }))
        this.pagination = {
          total: responseData.total,
          per_page: responseData.per_page,
          current_page: responseData.current_page,
          last_page: responseData.last_page,
        }
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
